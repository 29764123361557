import React from "react"
import loadingBars from "./images/bars.svg"

const Loading = () => {
  return (
    <>
      <div style={{ height: "100vh", textAlign: "center" }}>
        <img
          width="50"
          src={loadingBars}
          style={{ display: "inline-block", marginTop: "40vh" }}
          alt=""
        />
        <div style={{ fontSize: "16px",marginTop:"20px", fontWeight:"500", textAlign: "center", color: "#A3A5B6" }}>
          Loading Preview
        </div>
      </div>
    </>
  )
}

export default Loading
