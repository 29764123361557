const imgObject = `
  sourceUrl
  srcSet
  mediaDetails {
    height
    width
  }
`

const homeData = `
  homePageSections {
    homeheader {
      headerTitle1
      headerTitle2
      headerDescription
      headerImage {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
    }
    homequicklinks {
      page {
        ... on WPGraphQL_Page {
          id
          title
          link
          pageSettings {
            pageTheme
          }
        }
      }
      description
    }
  }

`

const footerQuery = `
  siteOptions {
    siteSettings {
      footerAcknowledgement
      footerCopyright
      footerLinksBottom {
        ... on Page {
          title
          link
        }
      }
      footerLinksOne {
        ... on Page {
          title
          link
        }
      }
      footerLinksTwo {
        ... on Page {
          title
          link
        }
      }
    }
  }
`

const navQuery = `
  siteOptions {
    siteSettings {
      bannerText
      nagivationDropdownText
      navigationDropdownItems {
      ... on Page {
        title
        link
        pageSettings {
          pageTheme
        }
      }
    }
    secondaryNavigationItems {
      isButton
      page {
        ... on Page {
          title
          link
        }
      }
    }
    }
  }
`



const pageData = `
  id
  title
  excerpt
  slug
  pageTemplate
  content
  link
  date
  content
  featuredImage {
    ${imgObject}
  }
  botSettings {
    stripMessage
  }

  pageSettings {
    pageTheme
    pageHeaderDescription
    feedbackQuestionText
    ctaBlock {
      title
      body
    }
  }

  ${homeData}

`

module.exports = {
  pageData,
  footerQuery,
  navQuery,
}
